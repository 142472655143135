@import "./_variables.scss";
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 1.125rem;
}

@import "../homePage/homePage";
@import "../commons/common";
@import "../ITSM/ITSM";
@import "../CyberSecurity/cyber-security";
@import "../BigData/bigdata";
@import "../DigitalOperationsConsulting/digital-operations-consulting";
@import "../RemoteWork/remotework";
@import "../DigitalTransformation/digitaltransformation";
@import "../EdTech/edTech";
@import "../DataAnalytics/data-analytics";
@import "../DigitalMarketing/digital-marketing";
@import "../BlockChain/block-chain";