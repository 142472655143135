.digital-op {
    & > .section-1 {
        text-align: center;
        margin-top: 2em;
        padding: 2em;
        @media (max-width: 500px) {
            margin-top: 0;
        }
        & > h3 {
            @media (max-width: 500px) {
                font-size: 20px;
            }
        }
        & > .content {
            margin-top: 1.2em;
            color: $darkGrey;
            @media (max-width: 500px) {
                font-size: 14px;
            }
        }
        & > .section-image {
            margin: auto;
            display: flex;
            height: 60vh;
            margin-top: 2em;
            @media (max-width: 600px) {
                height: 30vh;
            }
        }
    }
    & > .section-2 {
        padding: 4em 10em;
        @media (max-width: 500px) {
            padding: 2em;
        }
        & > .row {
            margin-top:4em;
            & > .col-lg-4 {
                & > img {
                    width: 60px;
                    height: 60px;
                }
                & > .content {
                    font-size: 14px;
                    margin-top: 1em;
                }
            }
        }
    }
    & > .section-3 {
        padding: 4em 10em;
        @media (max-width: 500px) {
            padding: 2em;
        }
    }
}