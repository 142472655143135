.container {
    & > .section-1 {
        text-align: center;
        margin-top: 2em;
        padding: 2em;
        @media (max-width: 500px) {
            margin-top: 0;
        }
        & > .content {
            margin-top: 1.2em;
            color: $darkGrey;
            @media (max-width: 500px) {
                font-size: 15px;
            }
        }
        & > .section-image {
            margin: auto;
            display: flex;
            margin-top: 2em;
            max-width: 90%;
            height: auto;
        }
    }

    & > .section-2 {
        padding: 4em 5em;
        @media (max-width: 500px) {
            padding: 2em;
        }
    }
}

.services {
    & > .col-lg-6 {
        margin-top: 3em;
        & > .icon {
            height: 60px;
            margin-bottom: 1.5em;
        }
        & > .content {
            text-align: justify;
            font-size: 14px;
        }
    }
}