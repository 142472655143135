.home {
  & > .sectionAlign {
    display: flex;
    align-items: center;
  }
  & > .section-1 {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../assets/bannerImage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    & > .title {
      color: $white;
      position: absolute;
      top: 30%;
      transform: translate(-50%, 50%);
      left: 50%;
      text-align: center;
      @media (max-width: 769px) {
        top: 5%;
      }
      & > .heading {
        font-size: 3em;
      }
      & > .sub-heading {
        font-size: 1.2em;
      }
    }
  }

  & > .section-2 {
    padding: 6em 3em;
    height: 100vh;
    @media (max-width: 769px) {
      padding: 2em;
      position: relative;
    }
    
    & > .row{
      @media (max-width: 769px) {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      & > .vision-img {
        background-image: url("../assets/vision.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 70vh;

        @media (max-width: 600px) {
          min-height: 225px;
        }
      }
      & > .vision-right {
        align-self: center;
        & > .content {
          @media (max-width: 500px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  & > .section-3 {
    padding: 6em 3em;
    background-color: $lightBlue;
    height: 100vh;
    @media (max-width: 769px) {
      padding: 2em;
      position: relative;
    }
    
    & > .row {
      @media (max-width: 769px) {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      & > .mission-img {
        background-image: url("../assets/mission2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        min-height: 70vh;
        display: inline-block;

        @media (max-width: 600px) {
          min-height: 250px;
        }
      }

      & > .mission-left {
        align-self: center;
        & > .content {
          @media (max-width: 500px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  & > .section-4 {
    padding: 6em 3em;
    @media (max-width: 500px) {
      padding: 2em;
    }
    & > .underline {
      width: 10.7em;
      height: 0.571em;
      background: $navyBlue;
      opacity: 0.9;
    }
    & > .row {
      margin-top: 6em;
      padding: 0 6em;
      @media (max-width: 550px) {
        padding: 0;
      }
      & > .col {
        text-align: -webkit-center;
        margin: 12px 8px;
        
        @media (max-width: 500px) {
          margin: 20px 8px;
        }
        
        & > .why-us {
          // background-image: url("../assets/whyUs.jpg");
          background-repeat: no-repeat;
          background-position: center center;
          height: 300px;
          width: 300px;
          margin-bottom: 10px;

          @media (max-width: 500px) {
            height: 250px;
            width: 250px;
          }
        }
        & > .title {
          font-weight: 600;
          @media (max-width: 500px) {
            font-size: 18px;
            margin-top: 12px;
          }
        }
        & > .content {
          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  & > .section-5 {
    padding: 6em 3em;
    background: $lightBlue;
    @media (max-width: 500px) {
      padding: 2em;
    }
    & > .underline {
      width: 10.7em;
      height: 0.571em;
      background: $navyBlue;
      opacity: 0.9;
    }

    & > .row {
      margin-top: 6em;
      padding: 0 6em;
      @media (max-width: 550px) {
        padding: 0;
      }
      & > .col-lg-4 {
        text-align: -webkit-center;
        padding: 2em 0;
        & > a {
          color: black;
          text-decoration: none;
          & > .why-us {
            background-repeat: no-repeat;
            background-position: center center;
            height: 200px;
            width: 200px;
            margin-bottom: 2em;
          }

          & > .title {
            font-weight: 600;
            font-size: 18px;
          }

          & > .content {
            font-size: 12px;
          }
        }
      }
    }
  }
}
