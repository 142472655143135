.ed-tech {
    & > .section-1 {
        text-align: center;
        margin-top: 2em;
        padding: 2em;
        & > .content {
            margin-top: 1.2em;
        }
        & > .section-image {
            margin: auto;
            display: flex;
            height: 60vh;
            margin-top: 2em;
        }
    }
    & > .section-2 {
        padding: 4em 10em;
    }
}