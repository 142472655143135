.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.animationLeft {
  animation-name: fadeInLeft;
  animation-duration: 3s;
  animation-delay: 1s;
}
.animationRight {
  animation-name: fadeInRight;
  animation-duration: 3s;
  animation-delay: 1s;
}

.animationZoomOut {
  animation-name: zoomOut;
  animation-duration: 3s;
  animation-delay: 0.2s;
}

.round-image {
  border-radius: 50%;
  background-size: cover;
  border: 12px solid #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

.round-short-image {
  border-radius: 50%;
  background-size: cover;
  border: 8px solid #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-10px);
      -moz-transform: translatex(-10px);
      -o-transform: translatex(-10px);
      transform: translatex(-10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-10px);
      -moz-transform: translatex(-10px);
      -o-transform: translatex(-10px);
      transform: translatex(-10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-100px);
      -moz-transform: translatex(-100px);
      -o-transform: translatex(-100px);
      transform: translatex(-100px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}

@-webkit-keyframes fadeInRight {
  from {
      opacity:0;
      -webkit-transform: translatex(10px);
      -moz-transform: translatex(10px);
      -o-transform: translatex(10px);
      transform: translatex(10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@-moz-keyframes fadeInRight {
  from {
      opacity:0;
      -webkit-transform: translatex(10px);
      -moz-transform: translatex(10px);
      -o-transform: translatex(10px);
      transform: translatex(10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@keyframes fadeInRight {
  from {
      opacity:0;
      -webkit-transform: translatex(100px);
      -moz-transform: translatex(100px);
      -o-transform: translatex(100px);
      transform: translatex(100px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(0.2, 0.2);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

