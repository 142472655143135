.block-chain {
    & > .section-1 {
        text-align: center;
        margin-top: 2em;
        padding: 2em;
        @media (max-width: 500px) {
            margin-top: 0;
        }
        & > h3 {
            font-size: 18px;
            color: $darkGrey;
            @media (max-width: 500px) {
                font-size: 14px;
            }
        }
        & > .content {
            margin-top: 1.2em;
            @media (max-width: 500px) {
                font-size: 14px;
            }
        }
        & > .section-image {
            margin: auto;
            display: flex;
            height: 60vh;
            margin-top: 2em;
            @media (max-width: 600px) {
                height: 175px;
            }
        }
    }
    & > .section-2, .section-3 {
        padding: 4em 10em;
        @media (max-width: 500px) {
            padding: 2em;
        }
    }
}