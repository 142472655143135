.button {
    border: none;
    height: 50px;
    padding: 0 15px;
    border-radius: 30px;
    background: white;
    position: absolute;
    bottom: 5em;
    left: 50%;
    transform: translate(-50%, 50%);
    font-size: 14px;
    & > i {
        margin-left: 5px;
    }

    @media (max-width: 450px) {
        font-size: 11px;
    }
}