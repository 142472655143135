.header {
    text-align: end;
    margin: 20px;
    font-size: 20px;
    @media (max-width: 450px) {
        font-size: 15px;
    }
    & > a {
        color: black;
        text-decoration: none;
        font-weight: 200;
        &:hover {
            cursor: pointer;
        }
    }
}