.footer {
  background: #000;
  padding: 2em;
  & > .row {
    align-items: center;
    & > .copyright {
      margin: auto;
      color: white;
      text-align: right;
      @media (max-width: 500px) {
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
      }
    }
    & > .col {
      & > .label {
        margin: auto;
        color: white;
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      & > .contact-section {
        line-height: 0.4;
        margin-top: 30px;
        @media (max-width: 500px) {
          margin-top: 8px;
        }
        & > .p-tag {
          font-size: 16px;
          & > .contact {
            margin: auto;
            color: white;
            text-decoration: none;
            @media (max-width: 500px) {
              font-size: 12px;
            }
          }
        }
        
      }
    }
    & > .col-6, .col-lg-4, .col-md-4 {
      @media (max-width: 500px) {
        font-size: 11px;
      }
      & > .social-media {
        display: flex;
        justify-content: end;
        margin: auto;
        & > li {
          list-style: none;
          font-size: 1.5em;
          margin: 0 10px;
          & > a {
            & > i {
              color: white;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    padding: 1em;
  }
}